<template>
  <div class="mp_collection">
    <div class="header">
      <search></search>
      <div class="collect_switch">
        <div class="sw_item" :class="[{'active': collectReq.use == 1}]" @click="changeUse(1)">用户采集</div>
        <div class="separator"></div>
        <div class="sw_item" :class="[{'active': collectReq.use == 0}]" @click="changeUse(0)">系统采集</div>
      </div>
      <div class="tabs">
        <div class="tab" :class="[{'active': tabIndex == index}]" v-for="(item, index) in tabs" :key="index" @click="changeTab(index)">{{item}}</div>
        <div class="line" :style="lineStyle"></div>
      </div>
      <div class="classify">
        <div class="current_classify" @click.stop="spread = !spread">
          {{classifyList[classifyIndex].title}}
          <i class="spread" :class="[spread ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i>
        </div>
        <div class="all_classify" :style="{height: spread ? Math.ceil(classifyList.length/2)*40+'px' : 0}">
          <div class="subitem" :class="[{'active': classifyIndex == index}]" v-for="(item, index) in classifyList" :key="index" @click="changeClassify(index)">{{item.title}}</div>
        </div>
      </div>
    </div>
    <div class="collection">
      <mp-waterfall :data="collectData" @loadmore="loadmore"></mp-waterfall>
    </div>
    <div class="load_more" v-if="loading">
      正在加载更多<i class="el-icon-loading"></i>
    </div>
    <no-more :show="noMore && collectReq.page != 1"></no-more>
  </div>
</template>

<script>
import Search from '@/mp/components/mp-collect-search';
import MpWaterfall from '@/mp/components/mp-waterfall';
import NoMore from '@/mp/components/mp-no-more';
export default {
  name: 'mp_collect_index',
  components: {
    Search,
    MpWaterfall,
    NoMore,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['最新', '采集最多', '点赞最多'],
      classifyIndex: 0,
      classifyList: [{id: 0, title: '全部分类'}],
      spread: false,
      collectData: [],
      collectReq: {catId: '', username: '', ord: 0, title: '', use: 0, page: 1},
      loading: false,
      noMore: false,
    }
  },
  computed: {
    lineStyle() {
      return {
        'width': 100/this.tabs.length + '%',
        'left': 100/this.tabs.length*this.tabIndex + '%'
      }
    }
  },
  methods: {
    changeUse(num) {  // 系统、用户采集
      this.collectReq.use = num;
      this.collectReq.page = 1;
      this.collectData = [];
      this.getCollectData();
    },
    changeTab(index) {  // 排序筛选
      this.tabIndex = this.collectReq.ord = index;
      this.collectReq.page = 1;
      this.collectData = [];
      this.getCollectData();
    },
    getCollectData() {  // 采集数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.collectReq.catId,
          username: this.collectReq.username,
          ord: this.collectReq.ord,
          title: this.collectReq.title,
          use: this.collectReq.use,
          page: this.collectReq.page,
        },
      }).then(res => {
        if (res.code == 200) {
          if (res.data.res.length < 20) {
            this.noMore = true;
          } else {
            this.collectData = this.collectData.concat(res.data.res)
          }
        }
      })
    },
    getClassify() { // 所有分类
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/catlist',
        method: 'GET',
      }).then(res => {
        if (res.code == 200) {
          this.classifyList = this.classifyList.concat(res.data ? res.data : []);
        }
      })
    },
    changeClassify(index) { // 分类筛选
      this.classifyIndex = index;
      this.collectReq.catId = this.classifyList[index].id;
      this.collectReq.page = 1;
      this.collectData = [];
      this.getCollectData();
    },
    loadmore() {  // 加载更多采集
      this.collectReq.page += 1;
      this.getCollectData();
    },
  },
  created() {
    this.getCollectData();
    this.getClassify();
  },
}
</script>

<style lang="scss" scoped>
.mp_collection {
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 70px;
  background: #f5f5f5;
  .header {
    box-shadow: 0 0 4px rgba(0,0,0,.2);
    .collect_switch {
      display: flex;
      background: #fafafa;
      text-align: center;
      border-top: 1px solid #ccc;
      .sw_item {
        flex: 1;
        padding: 8px 0;
        border-bottom: 1px solid #ccc;
        font-size: 1rem;
        color: #999;
        transition: all 1s;
        &.active {
          border-bottom: 1px solid transparent;
          color: #444;
          background: #fff;
        }
      }
      .separator {
        width: 1px;
        background: #ccc;
      }
    }
    .tabs {
      line-height: 30px;
      display: flex;
      padding: 10px 0;
      background: #fff;
      position: relative;
      .tab {
        flex: 1;
        text-align: center;
        font-size: 1rem;
        color: #707070;
        &.active {
          color: #e01b49;
        }
      }
      .line {
        height: 2px;
        position: absolute;
        bottom: 0;
        background-color: #e01b49;
        transition: all 360ms;
      }
    }
    .classify {
      background: #fff;
      line-height: 40px;
      text-align: center;
      position: relative;
      .current_classify {
        position: relative;
        border-bottom: 1px solid #ededed;
        &:active {
          background-color: #ccc;
          color: #fff;
        }
        .spread {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: #000;
          font-size: 20px;
          font-weight: 700;
        }
      }
      .all_classify {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        overflow: hidden;
        transition: all 360ms;
        .subitem {
          box-sizing: border-box;
          width: 50%;
          line-height: 40px;
          border-bottom: 1px solid #ededed;
          &.active {
            background-color: #f3f3f3;
          }
          &:nth-child(2n-1) {
            border-right: 1px solid #ededed;
          }
        }
      }
    }
  }
  .load_more {
    text-align: center;
    color: #c6c6c6;
  }
}
</style>